<!-- Layout.vue -->
<template>
    <div class="sb-nav-fixed">
        <div id="loader_container" class="d-none">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <HeaderComponent />
        <div id="layoutSidenav">
            <SidebarComponent />
            <div id="layoutSidenav_content">
                <main class="pt-4">
                    <router-view></router-view>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderComponent from './HeaderComponent.vue';
import SidebarComponent from './SidebarComponent.vue';

export default {
    components: {
        HeaderComponent,
        SidebarComponent
    }
}
</script>

<style></style>
