import axios from 'axios';
import config from '../../config/config';
import { tokenExpired } from '@/assets/js/utils';
const apiEndpoint = config.API_ENDPOINT;

const state = {
    profileInfo: {},
    socialList: [],
};

const getters = {
    getProfileInfoData: (state) => state.profileInfo,
    getSocialList: (state) => state.socialList,
};

const actions = {
    async fetchUserDetails({ commit, rootGetters }, body) {
        try {
            const userId = body && body.userId ? body.userId : rootGetters['user/getUserId'];
            const attendeeId = body && body.attendeeId ? body.attendeeId : null;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.get(`${apiEndpoint}/api/v1/get-user-details?id=${userId}&attendee_id=${attendeeId}`);
            commit('setProfileInfoData', response.data.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async updateHostProfile({ commit, rootGetters }, profileInfo) {
        const formData = new FormData();
        formData.append('id', rootGetters['user/getUserId']);
        formData.append('display_name', profileInfo.displayName);
        formData.append('name', profileInfo.name);
        formData.append('bio', profileInfo.bio);
        formData.append('phone', profileInfo.phone);
        formData.append('email', profileInfo.email);
        formData.append('date_of_birth', profileInfo.dateOfBirth);
        formData.append('gender', profileInfo.gender);
        formData.append('address', profileInfo.address);
        formData.append('zip', profileInfo.zip);
        formData.append('city', profileInfo.city);
        formData.append('state', profileInfo.state);
        formData.append('country', profileInfo.country);
        formData.append('default_login', profileInfo.default_login);
        formData.append('profile_image', profileInfo.profileImage);
        formData.append('cover_photo', profileInfo.coverImage);

        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(apiEndpoint + '/api/v1/update-host-profile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            commit('setProfileInfoData', response.data.data);
            commit('setUser', response.data.data);
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async updateHostProfileWallet({ commit, rootGetters }, walletInfo) {
        const formData = new FormData();
        formData.append('stripe_public_key', walletInfo.stripePublicKey);
        formData.append('stripe_secret_key', walletInfo.stripeSecretKey);
        formData.append('id', rootGetters['user/getUserId']);

        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(apiEndpoint + '/api/v1/update-host-profile-wallet', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            commit('setProfileInfoData', response.data.data);
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async updateAttendeeProfileWallet({ commit, rootGetters }, walletInfo) {
        const formData = new FormData();
        formData.append('stripe_card_number', walletInfo.cardNumber);
        formData.append('stripe_cvc', walletInfo.cvc);
        formData.append('stripe_expiry_month', walletInfo.expiryMonth);
        formData.append('stripe_expiry_year', walletInfo.expiryYear);
        formData.append('id', rootGetters['user/getUserId']);

        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.post(apiEndpoint + '/api/v1/update-attendee-profile-wallet', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            commit('setProfileInfoData', response.data.data);
            return response;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
    async fetchAllSocialList({ commit, rootGetters }) {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.get(`${apiEndpoint}/api/v1/all-social-link-list`);
            commit('setAllSocialList', response.data.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
};

const mutations = {
    setProfileInfoData: (state, profileInfo) => (state.profileInfo = profileInfo),
    setAllSocialList: (state, socialList) => (state.socialList = socialList),
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
