import axios from 'axios';
import config from '../../config/config';
import { tokenExpired } from '@/assets/js/utils';
const apiEndpoint = config.API_ENDPOINT;

const state = {
    hostDashboardData: {},
};

const getters = {
    getHostDashboardData: (state) => state.hostDashboardData,
};

const actions = {
    async fetchHostDashboardData({ commit, rootGetters }) {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootGetters['user/getAccessToken'];
            const response = await axios.get(`${apiEndpoint}/api/v1/get-host-dashboard-data?host_id=${rootGetters['user/getUserId']}`);
            commit('setHostDashboardData', response.data);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                tokenExpired();
            } else {
                return error.response;
            }
        }
    },
};

const mutations = {
    setHostDashboardData: (state, hostDashboardData) => {
        state.hostDashboardData = hostDashboardData
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
