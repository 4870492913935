// import store from "@/store";
import { Modal } from 'bootstrap';

export function openModal(modalId) {
    const myModal = new Modal(`#${modalId}`, {
        keyboard: false
    })
    myModal.show();
}
export function closeModal(modalId) {
    const modalElement = document.getElementById(modalId);
    if (!modalElement) {
        console.error(`Modal with id ${modalId} not found.`);
        return;
    }
    const myModal = Modal.getInstance(modalElement);
    if (myModal) {
        myModal.hide();
    }
}
export function formValidation(form) {
    const requiredFields = form.querySelectorAll('input[required], select[required]');
    let isFormValid = true;

    requiredFields.forEach(field => {
        if (!field.value.trim()) {
            isFormValid = false;
            const errorMessage = field.parentNode.querySelector('.error-tag');
            if (!errorMessage) {
                const errorMessage = document.createElement('span');
                errorMessage.textContent = 'This field is required';
                errorMessage.classList.add('error-tag');
                field.parentNode.appendChild(errorMessage);
            }
        } else {
            const errorMessage = field.parentNode.querySelector('.error-tag');
            if (errorMessage) {
                errorMessage.remove();
            }
        }
    });

    if (isFormValid) {
        return true;
    } else {
        return false;
    }
}
export function serverSideErrorsHtml(response, containerId) {
    const errors = response.data.data;

    const parentElement = document.getElementById(containerId);
    const errorElement = parentElement.querySelector('.server_side_errors');

    if (Object.keys(errors).length === 0) {
        errorElement.innerHTML = '';
    } else {
        let html = '';
        for (const field in errors) {
            errors[field].forEach(message => {
                html += `<p>${message}</p>`;
            });
        }
        errorElement.innerHTML = html;
    }
}
export function tokenExpired() {
    // store.dispatch('setAccessToken', null);
    // window.location.href = '/auth/login';
}
export function pageNotFound() {
    window.location.href = '/404';
}
