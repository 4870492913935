<template>
    <div>
        <img class="song-cover rounded " :src="song.song_image" alt="">
  
        <div class=" song-info">
            <div class="mt-3">
                <div>

                    <h5 class="text-white text-4 mb-1 text-break"> {{ song.song_name.length > 23 ? song.song_name.substring(0, 23) + '...' : song.song_name }}</h5>
                    <p class="text-white song-album">{{ song.song_artist.length > 23 ? song.song_artist.substring(0, 23) + '...' : song.song_artist }}</p>
                    <p class="song-album text-white">{{ song.album_name && song.album_name.length > 25 ? song.album_name.substring(0, 25) + '...' : song.album_name }}</p>
                    <a :href="song.song_link" target="_blank">
  <img src="../../assets/spotify.png" alt="Spotify Logo" class="spotifyicon mt-2">
</a>
                   
                    
                </div>
                <div class="d-flex align-items-center justify-content-between mt-2" >
                    <p>
                        <i title="Not played yet" v-if="song.status == 0" class="fa-solid fa-clock text-warning"></i>
                        <i title="Already played" v-if="song.status == 1" class="fa-solid fa-circle-check text-success"></i>
                    </p>
                    <template v-if="role == 2">
                        <div class="btn-group">
                            <button type="button" class="btn dropdown-toggle text-white" data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <i class="fa-solid fa-ellipsis-vertical"></i>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end">
                                <li><button v-if="song.status == 0" @click="markAs(song, 'played')" class="dropdown-item" type="button">Mark as played</button></li>
                                <li><button v-if="song.status == 1" @click="markAs(song, 'unplayed')" class="dropdown-item" type="button">Mark as unplayed</button></li>
                                <li><button @click="markAs(song, 'delete')" class="dropdown-item" type="button">Delete</button></li>
                            </ul>
                        </div>
                    </template>
                    <template v-else>
                        <button @click="cancelRequest(song)" type="button" class="btn text-danger p-1 lh-1"><i
                                class="fa-regular fa-circle-xmark"></i></button>
                    </template>
                </div>
            </div>
        </div>
        <p v-if="role == 2" class="text-neutral-25 requested-by">Requested by {{
            song.request_count
            }}
            attendee</p>
    </div>
</template>
<script scoped>
import { computed, inject } from 'vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';

export default ({
    props: {
        song: Object
    },
    setup() {
        const store = useStore();
        const role = computed(() => store.getters['user/getUserRole']);
        const route = useRoute();
        const toast = useToast();
        const eventId = route.params.eventId;
        const swal = inject('$swal');

        const cancelRequest = async (song) => {
            swal({
                title: 'Delete',
                text: 'Are you sure you want to proceed?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#6A04BA',
                confirmButtonText: 'Delete',
                showConfirmButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const body = {
                        song_id: song.song_id,
                        event_id: eventId,
                    };
                    const response = await store.dispatch('attendeeEvent/cancelSongRequest', body);
                    if (response.data.status) {
                        toast.success(response.data.msg);
                    } else {
                        toast.error(response.data.msg);
                    }
                }
            })
        }
        const markAs = async (song, markAs) => {
            const title = markAs == 'played' ? 'Mark as played!' : (markAs == 'Mark as unplayed' ? 'unplayed' : 'Delete!');
            const btnText = markAs == 'played' ? 'Mark as played!' : (markAs == 'Mark as unplayed' ? 'unplayed' : 'Delete!');
            const success = markAs == 'played' ? 1 : (markAs == 1 ? 'unplayed' : 0);
            swal({
                title: title,
                text: 'Are you sure you want to proceed?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: success ? '#6A04BA' : '#d33',
                cancelButtonColor: success ? '#d33' : '#6A04BA',
                confirmButtonText: btnText,
                showConfirmButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const body = {
                        song_id: song.song_id,
                        event_id: eventId,
                        mark_as: markAs,
                    };
                    const response = await store.dispatch('event/markAsSongRequest', body);
                    if (response.data.status) {
                        toast.success(response.data.msg);
                    } else {
                        toast.error(response.data.msg);
                    }
                }
            })
        }

        return {
            role,
            cancelRequest,
            markAs
        }
    }
})
</script>
<style scoped>
.spotifyicon{
    width: 20px;
}
.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 0.75rem;
}
.requested-by{
    font-size: 14px;
}
.overlay>div {
    background: #000000b0;
    height: 100%;
}

.song-cover {
    width: 100%;
}

.song-info {
    top: 3%;
    left: 8%;
    right: 8%;
}

.request-by {
    bottom: 2%;
    left: 10%;
}
</style>
