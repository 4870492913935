<template>

<div class="mt-4"  >
        <h2 class="text-24 text-neutral-25 mb-0" >Wallet</h2>
        <p class="text-neutral-100 text-3" >Add wallet for tips or Membership purchase, also withdraw your earnings</p>
    </div>
    <div class="container p-0 mt-4" >
        <div class="container p-0 mt-4">
        <div class="row">
            <div class="left col-12 col-sm-12 col-md-4 pb-3">
                <div class="bg-neutral-800 ps-3 pt-3 pe-3 rounded dashboard-card">
                    <div class="d-flex gap-2">
                        <div class="card-icon bg-neutral-900 ps-3 pe-3 pt-2 rounded  text-primary"><i
                                class="fa-solid fa-wallet"></i></div>
                        <div>
                            <h6 class="text-neutral-25 text-20 font-weight-600">PAYMENT METHODS</h6>
                            <p class="text-neutral-400 text-3">Manage your payment method</p>
                        </div>
                    </div>

                    <button class="btn btn-primary w-100 mt-4 mb-3" @click="connectStripeHandler">CONNECT
                        STRIPE</button>
                </div>
            </div>

        </div>
    </div>

    </div>

    <!-- <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">Card Number</label>
        <input type="text" v-model="walletForm.cardNumber">
    </div>
    <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">CVC</label>
        <input type="text" v-model="walletForm.cvc">
    </div>
    <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">Expiriy Month</label>
        <input type="text" v-model="walletForm.expiryMonth">
    </div>
    <div class="mb-3">
        <label for="exampleInputEmail1" class="form-label">Expiry Year</label>
        <input type="text" v-model="walletForm.expiryYear">
    </div>
    <button type="button" @click="saveWalletInfo">Save</button> -->
</template>
<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
export default ({
    props: {
        profileInfo: Object
    },
    setup(props) {
        const store = useStore();
        const walletForm = ref({
            cardNumber: props.profileInfo.stripe_card_number,
            cvc: props.profileInfo.stripe_cvc,
            expiryMonth: props.profileInfo.stripe_expiry_month,
            expiryYear: props.profileInfo.stripe_expiry_year,
        });

        watch(() => props.profileInfo, (newProfileInfo) => {
            walletForm.value.cardNumber = newProfileInfo.stripe_card_number;
            walletForm.value.cvc = newProfileInfo.stripe_cvc;
            walletForm.value.expiryMonth = newProfileInfo.stripe_expiry_month;
            walletForm.value.expiryYear = newProfileInfo.stripe_expiry_year;
        });

        const saveWalletInfo = async () => {
            await store.dispatch('hostProfile/updateAttendeeProfileWallet', walletForm.value);
        }

        return {
            walletForm,
            saveWalletInfo,
        }
    }
})
</script>
<style scoped >
.form-label{
    margin-bottom: 5px;
}
input {
    width: 100%;
    height: 44px;
    padding-left: 10px;
    padding-right: 10px;
    color: #E4E4E5;
    border: 1px solid #A4A4A5 ;
    background-color: #333334;
    border-radius: 4px;
}
input:focus{
    color: #E4E4E5;
    border: 1px solid #A4A4A5 ;
    background-color: #333334;
    border-radius: 4px;
    outline: none;
}
.history{
    width: 100%;
    height: 100%;
}
</style>
