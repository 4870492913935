<template>
    <h3 class="auth_page_titlte text-neutral-100">Email Verification</h3>
    <p class="text-neutral-200 mb-3">Please enter the code</p>

    <form class="w-100" @submit.prevent="verifyOtp">
        <div v-if="errorMessages.length" class="alert alert-danger alert-dismissible fade show" role="alert">
            <span v-for="(error, index) in errorMessages" :key="index" class="d-block" >{{ error.message }}</span> <br>
            <button type="button" @click="removeAlert()" class="btn-close"></button>
        </div>

        <div class="d-flex justify-content-evenly gap-2 mb-3">
            <input @input="handleInput($event, 1)" @paste="handlePaste($event)" @keyup="handleKeyPress($event, 1)"
                @focus="handleFocus($event, 1)" id="input1" v-model="form.otp1" type="number"
                class="otp_input_field form-control text-center" maxlength="1">
            <input @input="handleInput($event, 2)" @paste="handlePaste($event)" @keyup="handleKeyPress($event, 2)"
                @focus="handleFocus($event, 2)" id="input2" v-model="form.otp2" type="number"
                class="otp_input_field form-control text-center" maxlength="1">
            <input @input="handleInput($event, 3)" @paste="handlePaste($event)" @keyup="handleKeyPress($event, 3)"
                @focus="handleFocus($event, 3)" id="input3" v-model="form.otp3" type="number"
                class="otp_input_field form-control text-center" maxlength="1">
            <input @input="handleInput($event, 4)" @paste="handlePaste($event)" @keyup="handleKeyPress($event, 4)"
                @focus="handleFocus($event, 4)" id="input4" v-model="form.otp4" type="number"
                class="otp_input_field form-control text-center" maxlength="1">
            <input @input="handleInput($event, 5)" @paste="handlePaste($event)" @keyup="handleKeyPress($event, 5)"
                @focus="handleFocus($event, 5)" id="input5" v-model="form.otp5" type="number"
                class="otp_input_field form-control text-center" maxlength="1">
            <input @input="handleInput($event, 6)" @paste="handlePaste($event)" @keyup="handleKeyPress($event, 6)"
                @focus="handleFocus($event, 6)" id="input6" v-model="form.otp6" type="number"
                class="otp_input_field form-control text-center" maxlength="1">
        </div>
        <button type="submit" :disabled="btnLoading || btnDisabled" :class="[btnLoading ? 'btn_loading' : '', 'btn btn-primary w-100']">Verify</button>
    </form>
    <div class="d-flex justify-content-between my-3">
        <p class="text-neutral-100">Didn't receive the code?</p>
        <div>
            <button type="button" @click="resendOtp" :disabled="timer" :class="['bg-transparent border-0 text-blue-500']">Resend Code</button>
            <span v-if="timer" class="text-neutral-400">in {{ minutes }}:{{ seconds < 10 ? '0' + seconds : seconds }}</span>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { computed, inject, onMounted, onUnmounted, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    setup() {
        const store = useStore();
        const router = useRouter();
        const globalVariables = inject('globalVariables');
        const toast = useToast();
        const errorMessages = ref([]);
        const btnLoading = ref(false);
        const btnDisabled = ref(true);

        onUnmounted(() => {
            store.dispatch('user/setRegisteringEmail', '');
        })

        const previousValue = ref('');
        const showPassword = ref(false);

        const form = ref({
            email: store.getters['user/getRegisteringEmail'] || null,
            otp1: null,
            otp2: null,
            otp3: null,
            otp4: null,
            otp5: null,
            otp6: null,
        });

        const timer = ref(120);
        const timerActive = ref(false);
        const minutes = computed(() => Math.floor(timer.value / 60));
        const seconds = computed(() => timer.value % 60);

        const resendOtp = () => {
            timer.value = 120;
            timerActive.value = true;

            store.dispatch('user/reSendOtp', { type: 'email', email: store.getters['user/getRegisteringEmail'] });

            const interval = setInterval(() => {
                if (timer.value > 0) {
                    timer.value--;
                } else {
                    clearInterval(interval);
                    timerActive.value = false;
                }
            }, 1000);
        };

        onMounted(() => {
            const interval = setInterval(() => {
                if (timer.value > 0) {
                    timer.value--;
                } else {
                    clearInterval(interval);
                    timerActive.value = false;
                }
            }, 1000);
        })

        const verifyOtp = async () => {
            btnLoading.value = true;
            axios.post(globalVariables.baseURL + '/api/auth/verify-email', form.value)
                .then(() => {
                    router.push({
                        name: 'login',
                    });
                    toast.success('Email verified successfully!');
                    errorMessages.value = [];
                    btnLoading.value = false;
                })
                .catch((error) => {
                    if (error.response && error.response.status === 422) {
                        const errors = error.response.data.errors;
                        console.log(errors)
                        errorMessages.value = [];
                        for (const field in errors) {
                            errors[field].forEach(message => {
                                let lastElement = errorMessages.value[errorMessages.value.length - 1];
                                if (!lastElement) {
                                    lastElement = {id: 0};
                                }
                                const messageObj = {
                                    id: lastElement.id + 1,
                                    message: message
                                };
                                errorMessages.value.push(messageObj);
                                console.log(errorMessages.value)
                            });
                        }
                    } else {
                        errorMessages.value = [{id: 1, message: error.response.data.msg}];
                    }
                    btnLoading.value = false;
                })
        };

        const handleKeyPress = (event, num) => {
            const nextInput = document.getElementById(`input${num + 1}`);
            const previousInput = document.getElementById(`input${num - 1}`);
            if (event.key == 'Backspace' && num != 1) {
                if (!previousValue.value) {
                    previousInput.focus();
                } else {
                    previousValue.value = null;
                }
            } else if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(event.key)) {
                if (num != 6) {
                    nextInput.focus();
                } else {
                    previousValue.value = event.target.value;
                }
            }
        };

        const handleFocus = (event) => {
            previousValue.value = event.target.value;
        };

        const handlePaste = (event) => {
            event.preventDefault();
            const pasteData = event.clipboardData.getData('text').trim().substring(0, 6);
            const characters = pasteData.split('');
            form.value.otp1 = characters[0];
            form.value.otp2 = characters[1];
            form.value.otp3 = characters[2];
            form.value.otp4 = characters[3];
            form.value.otp5 = characters[4];
            form.value.otp6 = characters[5];
            document.getElementById('input6').focus();
            btnDisabled.value = false;
        };

        const handleInput = (event, num) => {
            if (event.target.value.length > 1) {
                switch (num) {
                    case 1:
                        form.value.otp1 = parseInt(event.target.value[event.target.value.length - 1]);
                        break;
                    case 2:
                        form.value.otp2 = parseInt(event.target.value[event.target.value.length - 1]);
                        break;
                    case 3:
                        form.value.otp3 = parseInt(event.target.value[event.target.value.length - 1]);
                        break;
                    case 4:
                        form.value.otp4 = parseInt(event.target.value[event.target.value.length - 1]);
                        break;
                    case 5:
                        form.value.otp5 = parseInt(event.target.value[event.target.value.length - 1]);
                        break;
                    case 6:
                        form.value.otp6 = parseInt(event.target.value[event.target.value.length - 1]);
                        break;
                    default:
                        break;
                }
            }
            if (
                (typeof form.value.otp1 === 'number' && !isNaN(form.value.otp1)) &&
                (typeof form.value.otp2 === 'number' && !isNaN(form.value.otp2)) &&
                (typeof form.value.otp3 === 'number' && !isNaN(form.value.otp3)) &&
                (typeof form.value.otp4 === 'number' && !isNaN(form.value.otp4)) &&
                (typeof form.value.otp5 === 'number' && !isNaN(form.value.otp5)) &&
                (typeof form.value.otp6 === 'number' && !isNaN(form.value.otp6))
            ) {
                btnDisabled.value = false;
            } else {
                btnDisabled.value = true;
            }
        };

        const togglePasswordVisibility = () => {
            showPassword.value = !showPassword.value;
        };

        const removeAlert = () => {
            errorMessages.value = [];
        }


        return {
            verifyOtp,
            showPassword,
            togglePasswordVisibility,
            handleKeyPress,
            handleFocus,
            handlePaste,
            handleInput,
            router,
            globalVariables,
            toast,
            form,
            errorMessages,
            timer,
            timerActive,
            minutes,
            seconds,
            resendOtp,
            removeAlert,
            btnLoading,
            btnDisabled
        };
    }
};
</script>



<style>
/* Hide the spinner controls for number input */
.otp_input_field[type=number]::-webkit-inner-spin-button,
.otp_input_field[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.otp_input_field[type=number] {
    -moz-appearance: textfield;
}
</style>
