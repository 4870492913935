<script>
import { inject } from 'vue';

export default {
    setup() {
        const swal = inject('$swal');
        const loginAlert = () => {
            swal({
                title: 'Please register first!',
                // text: 'Please login first.',
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#6A04BA',
                confirmButtonText: 'Ok',
                showConfirmButton: true
            })
        };

        return { loginAlert };
    }
}
</script>
