import config from '@/config/config';
class Token {
    isValid(token) {
        const payload = this.payload(token);
        if (payload) {
            return payload.iss === `${config.API_ENDPOINT}/api/auth/login` || payload.iss === `${config.API_ENDPOINT}/api/auth/signup`;
        }
        return false;
    }

    payload(token) {
        const payload = token.split('.')[1];
        return this.decode(payload);
    }

    decode(payload) {
        return JSON.parse(atob(payload));
    }
}

const TokenInstance = new Token();

export default TokenInstance;
