<template>
    <h3 class="auth_page_titlte text-neutral-100">Login</h3>
    <p class="text-neutral-200 mb-3">Please enter your email and password to login</p>

    <form class="w-100" @submit.prevent="login">
        <div v-for="(error, index) in errorMessages" :key="index" class="alert alert-danger alert-dismissible fade show" role="alert">
            {{ error.message }}
            <button type="button" @click="removeAlert(error.id)" class="btn-close"></button>
        </div>

        <div class="form-floating mb-3">
            <input type="email" class="form-control" id="email" placeholder="Email" name="email" v-model="form.email"
                required>
            <label for="email">Email <span class="text-danger">*</span></label>
        </div>
        <div class="form-floating mb-3 position-relative">
            <input :type="showPassword ? 'text' : 'password'" class="form-control" id="password" placeholder="Password"
                v-model="form.password" required>
            <label for="password">Password <span class="text-danger">*</span></label>
            <div class="eye-icon-container position-absolute top-0 bottom-0 end-0 d-flex align-items-center">
                <button type="button" class="btn eye-icon-btn" @click="togglePasswordVisibility"><i
                        :class="[showPassword ? 'fa-eye' : 'fa-eye-slash', 'fa-regular']"></i></button>
            </div>
        </div>
        <div class="d-flex justify-content-between mb-3">
            <div class="form-check d-flex align-items-center gap-2">
                <input :checked="isRememberMe" v-model="isRememberMe" class="form-check-input login-checkbox " type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label text-neutral-400 p-0" for="flexCheckDefault">
                    Remember me
                </label>
            </div>
            <router-link class="small" :to="{ name: 'reset.password' }">Forgot Password?</router-link>
        </div>
        <button type="submit" :disabled="btnLoading" :class="[btnLoading ? 'btn_loading' : '', 'btn btn-primary w-100']">Login</button>
    </form>
    <p class="text_middle_line mt-3 mb-3">Don't have an account?</p>
    <button v-if="qrValue == 'y'" @click="loginAsGuest" type="type" class="mt-3 btn text-white guest_btn w-100 mb-2">Login as Guest</button>
    <router-link class="btn w-100 text-neutral-25 border-primary" :to="{ name: 'register' }">Register</router-link>
</template>

<script>
import axios from 'axios'; // Import Axios here
import { computed, inject, onMounted, ref } from 'vue';
import Users from '../../Helpers/User.js';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

    export default {
        setup() {
            const router = useRoute();
            const store = useStore();
            const globalVariables = inject('globalVariables');
            const showPassword = ref(false);
            const toast = useToast();
            const btnLoading = ref(false);
            const isRememberMe = ref(false);
            const rememberMe = computed(() => store.getters['user/getRememberMe']);
            const rememberEmail = computed(() => store.getters['user/getRememberEmail']);
            const rememberPassword = computed(() => store.getters['user/getRememberPassword']);
            const togglePasswordVisibility = () => {
                showPassword.value = !showPassword.value;
            };
            const qrValue = router.query.qr;
            if (qrValue == 'y') {
                toast.error('Please login first.');
            }
            const form = ref({
                email: null,
                password: null,
            });
            const errorMessages = ref([]);

            onMounted(() => {
                if (rememberMe.value) {
                    isRememberMe.value = rememberMe.value;
                    form.value.email = rememberEmail.value;
                    form.value.password = rememberPassword.value;
                }
            })

            const login = () => {
                btnLoading.value = true;
                axios.post(globalVariables.baseURL + '/api/auth/login', form.value)
                    .then(res => {
                        Users.responseAfterLogin(res);
                        store.dispatch('user/setUserRole', res.data.default_login);
                        if (res.data.default_login == 2) {
                            const redirectPath = store.getters['user/getRedirectPath'] || '/host/dashboard';
                            store.dispatch('user/setRedirectPath', null);
                            window.location = redirectPath;
                        } else if(res.data.default_login == 3) {
                            const redirectPath = store.getters['user/getRedirectPath'] || '/attendee/dashboard';
                            store.dispatch('user/setRedirectPath', null);
                            window.location = redirectPath;
                        }
                        handleRememberMe();
                        btnLoading.value = false;
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 401) {
                            const errors = error.response.data;
                            errorMessages.value = [];
                            for (const field in errors) {
                                errors[field].forEach(message => {
                                    let lastElement = errorMessages.value.pop();
                                    if (!lastElement) {
                                        lastElement = {id: 0};
                                    }
                                    const messageObj = {
                                        id: lastElement.id + 1,
                                        message: message
                                    };
                                    errorMessages.value.push(messageObj);
                                });
                            }
                        } else {
                            errorMessages.value = [];
                            console.error('Error:', error);
                            errorMessages.value = ['An unexpected error occurred'];
                        }
                        btnLoading.value = false;
                    });
            };

            const handleRememberMe = () => {
                if (isRememberMe.value) {
                    store.dispatch('user/setRememberMe', true);
                    store.dispatch('user/setRememberEmail', form.value.email);
                    store.dispatch('user/setRememberPassword', form.value.password);
                } else {
                    store.dispatch('user/setRememberMe', false);
                    store.dispatch('user/setRememberEmail', '');
                    store.dispatch('user/setRememberPassword', '');
                }
            }

            const removeAlert = id => {
                errorMessages.value = errorMessages.value.filter(error => error.id !== id);
            }

            const loginAsGuest = async () => {
                const response = await store.dispatch('user/loginAsGuest');
                Users.responseAfterLogin(response.data);
                window.location = store.getters['user/getRedirectPath'];
            }

            return {
                showPassword,
                togglePasswordVisibility,
                form,
                login,
                errorMessages,
                qrValue,
                loginAsGuest,
                removeAlert,
                btnLoading,
                isRememberMe
            };
        }
    }

</script>
<style scoped >
.login-checkbox{
    width: 20px !important;
    height: 20px !important;
}
.guest_btn {
    background: #d66c4e;
}
.guest_btn:hover {
    background: #DA8068;
}

</style>
