<template>
    <div class="event-wrapper">
        <div>
            <div class="d-flex justify-content-between align-items-center">
                <h3 class="text-neutral-25  text-24">Events</h3>
                <button type="button" class="btn-primary text-neutral-25 new-event-button"
                    @click="openModal('eventCreateModal')"><i class="fa-solid fa-plus"></i> Create
                    Event</button>
                <!-- <button type="button" class="btn-primary text-neutral-25 new-event-button" data-bs-toggle="modal" data-bs-target="#eventCreateModal"><i class="fa-solid fa-plus"></i> Create
                    Event</button> -->
            </div>
            <div class="datatable_header m-0 bg-neutral-800 mt-4">
                <div class="d-flex event-list-head">
                    <div class="d-flex align-items-center gap-2 pb-2">
                        <h5 class="text-neutral-25 m-0 text-nowrap text-20">Event Table</h5>
                        <span class="badge rounded-pill bg-neutral-900 text-primary">{{ events.length }} Events</span>
                    </div>
                    <div class="container">
                        <div class="row input-wraper gap-2">
                            <div class=" col-12 col-sm-12 col-md-6 col-lg-4 search-box search-container d-flex align-items-center mb-2 ">
                                <button class="search-container search-icon text-4"><span
                                        class="fa-solid fa-magnifying-glass"></span></button>
                                <input @input="searchHandler" class="search search-container text-4 " type="text" v-model="search"
                                    placeholder="Search" id="search-input" value="">
                            </div>
                            <!-- <div class="filter-box search-container d-flex align-items-center col-3 ms-2">
                                <select v-model="filter" role="button" class="filter search-container text-4 position-relative">
                                    <option disabled value=''>Filter</option>
                                    <option>option</option>
                                    <option>option</option>
                                    <option>option</option>
                                </select>
                                <button class="search-container search-icon text-4 position-absolute"><span
                                        class="fa-solid fa-filter"></span></button>

                            </div> -->
                            <div class="filter-box search-container d-flex align-items-center col-12 col-sm-12 col-md-4 col-lg-3 mb-2">

                                <select @change="fetchEvents" v-model="sort" class="filter search-container text-4 position-relative" role="button">
                                    <option value=''>Sort</option>
                                    <option value="name">Name</option>
                                    <option value="start_time">Start Time</option>
                                </select>
                                <button class="search-container search-icon text-4 position-absolute"><span
                                        class="fa-solid fa-arrow-down-short-wide"></span></button>
                                <!-- <input  type="text" name="focus"
                                    placeholder="Sort" id="search-input" value=""> -->
                            </div>
                    </div>
                   </div>
                </div>
                <div class="table-container">
                    <table class="table ">
                        <thead>
                            <tr>
                                <th class="bg-neutral-800 text-neutral-200 text-3 ps-4" cope="col">Event Banner</th>
                                <th class="bg-neutral-800 text-neutral-200 text-3" scope="col">Event Name</th>
                                <th class="bg-neutral-800 text-neutral-200 text-3" scope="col">Start Time</th>
                                <th class="bg-neutral-800 text-neutral-200 text-3" scope="col">End Time </th>
                                <th class="bg-neutral-800 text-neutral-200 text-3" scope="col">Location</th>
                                <th class="bg-neutral-800 text-neutral-200 text-3" scope="col">Joined Users </th>
                                <th class="bg-neutral-800 text-neutral-200 text-3" scope="col">Song Request </th>
                                <!-- <th class="bg-neutral-800 text-neutral-200 text-3" scope="col">Status</th> -->
                            </tr>
                        </thead>
                        <tbody v-if="!loading" class="responsive-table">
                            <tr v-for="event in events" :key="event.id">
                                <th scope="row"><img class="event-list-image ps-3 object-fit-cover" :src="event?.banner_path || require('@/assets/util/images/no_event_banner.jpg')" alt="">
                                </th>
                                <td>{{ event.name }}</td>
                                <td>{{ event.formatted_start_time }}</td>
                                <td>{{ event.formatted_end_time }}</td>
                                <td>{{ event.address || 'N/A' }}</td>
                                <td class="text-center">{{ event.enrolled_users_count }}</td>
                                <td class="text-center">{{ event.song_requests_count }}</td>
                                <!-- <td><span class="badge text-bg-light text-primary"><i
                                            class="fa-solid fa-circle text-primary upcoming-icon"></i> Upcoming</span>
                                </td> -->
                                <td class="event_component_action_column">
                                    <span class="d-flex flex-column flex-lg-row gap-2 ">
                                        <div class="d-flex gap-2">
                                            <router-link :to="{ name: 'host.event.details', params: { eventId: event.id } }"
                                            class="btn p-0"><i
                                                class="fa-solid fa-eye text-primary text-3"></i></router-link>
                                            <button @click="openQrCodeModal(event)" class="btn p-0"><i
                                                    class="fa-solid fa-qrcode text-primary text-3 "></i></button>
                                        </div>
                                        <div class="d-flex gap-2">
                                            <button @click="openEditModal(event)" class="btn p-0"><i
                                                class="fa-solid fa-file-pen text-primary text-3"></i></button>
                                            <button @click="deleteEvent(event.id)" class="btn p-0"><i
                                                    class="fa-solid fa-trash text-danger text-3"></i></button>
                                        </div>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p class="text-neutral-25 d-flex justify-content-center pt-3 pb-2 "
                        v-if="!events.length && !loading"> No Event Found </p>
                </div>
                <div v-if="loading" class="d-flex align-items-center justify-content-center">
                    <LoadingComponent />
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between p-3 mb-2 bg-neutral-800 rounded">
            <button :disabled="pagination.currentPage == 1" @click="paginationHandler(parseInt(pagination.currentPage) - 1)" class="btn btn-primary">Previous</button>

            <div>
                <div class="pagination">
                    <button :disabled="pagination.currentPage == pageNumber" @click="paginationHandler(pageNumber)" v-for="pageNumber in pagination.lastPage" :key="pageNumber"
                        :class="['btn', 'btn-secondary', { 'btn-primary': pagination.currentPage == pageNumber }]">
                        {{ pageNumber }}
                    </button>
                </div>
            </div>

            <button :disabled="pagination.currentPage == pagination.lastPage" @click="paginationHandler(parseInt(pagination.currentPage) + 1)" class="btn btn-primary">Next</button>
        </div>
    </div>

    <EventCreateForm />
    <EventEditForm :event="selectedEvent" :updateEventValue="updateEventValue" />
    <QrcodeModal :src="selectedEvent.qrcode_path" :code="selectedEvent.code" :basePath="selectedEvent.qrcode_base_path" :url="selectedEvent.url" :type="'event'"/>
</template>

<script>
import { openModal } from '@/assets/js/utils';
import { useStore } from 'vuex';
import { ref, onMounted, inject } from 'vue';
import EventCreateForm from './EventCreateForm.vue';
import EventEditForm from './EventEditForm.vue';
import LoadingComponent from '@/components/Shared/LoadingComponent.vue';
import { useToast } from 'vue-toastification';
import QrcodeModal from '@/components/Host/Event/QrcodeModal.vue'

export default {
    components: {
        EventCreateForm,
        EventEditForm,
        LoadingComponent,
        QrcodeModal
    },
    setup() {
        const store = useStore();
        const events = ref([]);
        let loading = ref(false)
        const selectedEvent = ref({});
        const swal = inject('$swal');
        const toast = useToast();
        const pagination = ref({});
        const search = ref('');
        const filter = ref('');
        const sort = ref('');
        const page = ref(1);
        let searchTimer = null;

        onMounted(async () => {
            await fetchEvents();
        });

        const fetchEvents = async () => {
            try {
                loading.value = true;

                const body = {
                    date: null,
                    page: page.value,
                    search: search.value,
                    filter: filter.value,
                    sort: sort.value,
                };

                await store.dispatch('event/fetchEvents', body);
                loading.value = false;
                events.value = store.getters['event/allEvents'];
                pagination.value = store.getters['event/getHostEventPagination'];
            } catch (error) {
                console.error(error);
            }
        };
        const paginationHandler = async (pageNum) => {
            page.value = pageNum;
            await fetchEvents();
        }
        const deleteEvent = async (eventId) => {
            swal({
                title: 'Delete',
                text: 'Are you sure you want to proceed?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#6A04BA',
                confirmButtonText: 'Delete',
                showConfirmButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await store.dispatch('event/deleteEvent', eventId);
                    events.value = store.getters['event/allEvents'];
                    if (response.status) {
                        toast.success(response.msg);
                    } else {
                        toast.error(response.msg);
                    }
                }
            })
        };
        const openEditModal = (event) => {
            selectedEvent.value = event;
            openModal('eventEditModal');
        }
        const openQrCodeModal = (event) => {
            selectedEvent.value = event;
            openModal('qrcodeModal');
        }
        const searchHandler = () => {
            if (searchTimer) {
                clearTimeout(searchTimer);
            }
            searchTimer = setTimeout(async () => {
                fetchEvents()
            }, 500);
        }
        const updateEventValue = () => { }

        return {
            openModal,
            events, deleteEvent,
            selectedEvent,
            openEditModal,
            updateEventValue,
            loading,
            openQrCodeModal,
            paginationHandler,
            pagination,
            search,
            filter,
            sort,
            fetchEvents,
            searchHandler
        }
    }
}
</script>
<style>
input:focus {
    background-color: #484848 !important;
}

.event-wrapper {
    min-height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.event-list-image {
    width: 112px;
    height: 64px;
}

.responsive-table {
    overflow-x: auto !important;
}

.table tbody th,
.table tbody td {
    background: #242425 !important;
    border-bottom: 1px solid #484848 !important;
    color: white !important;
}

.table thead th {
    border-bottom: none !important;
}

.upcoming-icon {
    font-size: 10px !important;
}

.new-event-button {
    border-radius: 4px;
    padding: 10px;
    border: none;
}

.search-box {
    padding: 10px 10px;
    border: none;
    /* width: 373px; */
    border-radius: 4px;
    transition: .2s;
    height: 44px;
}

.filter-box {
    padding: 10px 10px;
    border: none;
    border-radius: 4px;
    transition: .2s;
    height: 44px;
}

.search {
    border: none;
    width: 100%
        /* width: 300px; */
}

.filter {
    width: 100%;
    border: none;
    outline: none;
    padding-left: 30px;
}

.search-icon:hover {
    cursor: pointer;
}

.search::placeholder {
    color: #E4E4E5;
}

.filter::placeholder {
    color: #E4E4E5;
}

.search:focus {
    outline: none;
}

.search-icon {
    border: none;
}

select {
    appearance: none;
}
.event-list-head {
    justify-content: space-between;
    align-items: center !important;
}

.input-wraper {
    justify-content: end !important;
}
select option {
    padding-left: 20px !important;
}

@media (max-width: 992px) {
    .table-container {
        overflow-x: scroll;
    }
    .event-list-head {
        flex-direction: column;
        justify-content: start !important;
        align-items: start !important;
    }

    .input-wraper {
        justify-content: start !important;
    }
}

.event_component_action_column .btn:active {
    border-color: transparent !important;
}
</style>
