<template>
    <div class="modal fade" id="eventCreateModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog text-neutral-25 ">
            <div class="modal-content bg-neutral-800">
                <div class="modal-header justify-content-between">
                    <h1 class="modal-title fs-5 text-20 font-weight-600 " id="exampleModalLabel">CREATE EVENT</h1>
                    <button type="button" class="bg-neutral-800 border-0" data-bs-dismiss="modal" aria-label="Close">
                        <i class="fa-solid fa-times text-neutral-25"></i>
                    </button>
                </div>
                <div class="modal-body text-neutral-25 ">
                    <form id="eventCreateForm" @submit.prevent="createEvent" novalidate>
                        <div class="position-relative">
                            <label v-if="!isCropping" for="eventBanner" class="file-upload rounded position-relative">
                                <img v-if="coverImg" class="coverimage object-fit-cover rounded w-100 h-100"
                                    :src="coverImg ? coverImg : ''" />
                                <span class="position-absolute upload_icon"><i class="fa-solid fa-upload"></i></span>
                            </label>
                            <cropper class="cover-cropper" v-if="isCropping" :src="coverImg" :stencil-props="stencilProps" @change="change" ref="cropperRef" />
                            <div class="position-absolute top-0 end-0">
                                <button @click="handleIsCropping" type="button" class="btn bg-transparant">
                                    <i v-if="!isCropping" class="fa-solid fa-crop-simple"></i>
                                    <i v-if="isCropping" class="fa-solid fa-circle-check"></i>
                                </button>
                            </div>
                            <input type="file" class="file  border-0 d-none" id="eventBanner" @change="onFileChange">
                        </div>
                        <div>
                            <label for="eventName" class="mt-3">Event Name:</label> <br>
                            <input class="bg-neutral-700 text-neutral-25 create-event-input rounded mt-1" type="text"
                                id="eventName" placeholder="Event Name" v-model="newEvent.name" required>
                        </div>
                        <div class="d-flex mt-3 gap-3">
                            <div class="event-start">
                                <label for="eventStartTime">Event Start Time:</label>
                                <input class="bg-neutral-700 text-neutral-25 create-event-input rounded mt-1"
                                    type="datetime-local" id="eventStartTime" v-model="newEvent.start_time" required
                                    placeholder="Select a Date & Time" onfocus="this.showPicker()">
                            </div>
                            <div class="event-start">
                                <label for="eventEndTime">Event End Time:</label>
                                <input class="bg-neutral-700  text-neutral-25 create-event-input rounded mt-1"
                                    type="datetime-local" id="eventEndTime" v-model="newEvent.end_time" required onfocus="this.showPicker()"><br>
                            </div>
                        </div>
                        <div>
                            <label class="mt-3" for="eventAddress">Address:</label>
                            <input class="bg-neutral-700 text-neutral-25 create-event-input rounded mt-1" type="text"
                                id="eventAddress" v-model="newEvent.address" placeholder="Address">
                        </div>
                        <div class="d-flex mt-3 gap-3">
                            <div class="w-100">
                                <label for="eventZip">Zip:</label> <br>
                                <input class="bg-neutral-700 text-neutral-25 create-event-input rounded mt-1"
                                    type="text" id="eventZip" v-model="newEvent.zip" placeholder="Zip"><br>
                            </div>
                            <div class="w-100">
                                <label for="eventCity">City:</label> <br>
                                <input class="bg-neutral-700 text-neutral-25 create-event-input rounded mt-1"
                                    type="text" id="eventCity" placeholder="City" v-model="newEvent.city"><br>
                            </div>
                        </div>
                        <div>
                            <label class="mt-3" for="eventState">State:</label> <br>
                            <input class="bg-neutral-700 text-neutral-25 create-event-input rounded mt-1"
                                placeholder="State" type="text" id="eventState" v-model="newEvent.state">
                        </div>
                        <div class="mb-3">
                            <label class="mt-3" for="eventCountry">Country:</label> <br>
                            <VueSelect2 v-model="newEvent.country" :options="options" :placeholder="'Select Country'" :settings="selectSettings" />
                        </div>
                        <div class="server_side_errors"></div>
                        <div class="modal-footer">
                            <button type="submit" :class="[btnLoading ? 'btn_loading' : '', 'btn btn-primary']">Create</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { closeModal, formValidation, serverSideErrorsHtml } from '@/assets/js/utils';
import { useToast } from 'vue-toastification';
import { countries } from '@/assets/js/country.js';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
    components: {
        Cropper,
    },
    setup() {
        const toast = useToast();
        const coverImg = ref(null);
        const store = useStore();
        const btnLoading = ref(false);
        const options = countries;
        const isCropping = ref(false);
        const cropperRef = ref(null);
        const newEvent = ref({
            name: '',
            start_time: '',
            end_time: '',
            address: '',
            zip: '',
            city: '',
            state: '',
            country: '',
            banner: null,
        });

        const selectSettings = {
            dropdownParent: '#eventCreateForm',
        };

        const createEvent = async () => {
            btnLoading.value = true;
            const isValid = formValidation(document.getElementById('eventCreateForm'));
            if (isValid) {
                const eventData = {
                    name: newEvent.value.name,
                    start_time: newEvent.value.start_time,
                    end_time: newEvent.value.end_time,
                    address: newEvent.value.address,
                    zip: newEvent.value.zip,
                    city: newEvent.value.city,
                    state: newEvent.value.state,
                    country: newEvent.value.country,
                    banner: newEvent.value.banner,
                    hostId: store.getters['user/getUserId'],
                };
                const response = await store.dispatch('event/addEvent', eventData);
                                                                      
                if (response.data.status) {
                    btnLoading.value = false;
                    closeModal('eventCreateModal');

                    newEvent.value.name = '';
                    newEvent.value.start_time = '';
                    newEvent.value.end_time = '';
                    newEvent.value.address = '';
                    newEvent.value.zip = '';
                    newEvent.value.city = '';
                    newEvent.value.state = '';
                    newEvent.value.country = '';
                    coverImg.value = '';
                    toast.success(response.data.msg);
                    window.location.reload();
                } else {
                    btnLoading.value = false;
                    if (response.status == 422) {
                        serverSideErrorsHtml(response, 'eventCreateModal')
                    } else {
                        toast.error(response.data.msg);
                    }
                }
            } else {
                btnLoading.value = false;
            }
        };

        const onFileChange = (event) => {
            if (event.target.files[0]) {
                const file = event.target.files[0];
                const reader = new FileReader();
                reader.onload = () => {
                    coverImg.value = reader.result;
                    newEvent.value.banner = reader.result;
                };
                reader.readAsDataURL(file);
            }
        };

        const handleIsCropping = () => {
            isCropping.value = !isCropping.value;
            if (!isCropping.value) {
                const { coordinates, canvas, } = cropperRef.value.getResult();
                // coordinates.value = coordinates;
                console.log(coordinates)
                coverImg.value = canvas.toDataURL();
                newEvent.value.banner = canvas.toDataURL();
                console.log(newEvent.value.banner)
            }
        }


        return {
            newEvent,
            coverImg,
            onFileChange,
            createEvent,
            countries,
            btnLoading,
            options,
            selectSettings,
            isCropping,
            handleIsCropping,
            cropperRef,
        };
    }
};
</script>
<style scoped>
.selected-image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 40%;
}

.event-start {
    width: 48% !important;
}

.create-event-input {
    width: 100% !important;
    border: 2px solid #A4A4A5;
    height: 44px;
    padding-left: 10px;

}

.create-event-input::placeholder {
    color: #FCFCFC;
}

.modal-footer {
    border-top: none !important;
}

label .file[type='file'] {
    display: none;
}

.upload_icon {
    cursor: pointer;
    background-color: #171718;
    padding: 10px 15px;
    border-radius: 4px;

}

.file-upload {
    width: 100%;
    height: 180px;
    background-color: #333334;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coverimage {
    width: 100%
}
.cover-cropper {
    width: 100%;
    height: 180px;
}
</style>
