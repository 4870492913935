<template>
    <nav class="sb-topnav navbar navbar-expand bg-neutral-900 navbar-dark">
        <router-link class="navbar-brand text-center ps-3" v-on:click="role == 4 && loginAlert.loginAlert()"
            :to="role != 4 ? (role == 2 ? { name: 'host.dashboard.index' } : { name: 'attendee.dashboard.index' }) : { path: $route.path, query: $route.query }">
            <img :src="require('@/assets/util/images/logo.png')" alt="Logo">
        </router-link>
        <button class="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!">
            <i class="fas fa-bars"></i>
        </button>
        <ul class="d-flex align-items-center ms-auto me-0 me-md-3 my-2 my-md-0 me-lg-4 gap-3">
            <!-- <li>
                <div
                    class="nav-profile-dropdown notification  text-neutral-25 bg-neutral-900  rounded-circle d-flex align-items-center justify-content-center">
                    <i class="fa-solid fa-bell"></i>
                </div>
            </li> -->
            <li class="">
                <div class="ok">
                    <div class="admin-profile">
                        <div class="dropdown">
                            <button @click="profileClick" href="#"
                                class="topimage bg-transparent border-0 dropdown-toggle" id="navbarDropdown"
                                role="button" aria-expanded="false" data-bs-toggle="dropdown">
                                <div
                                    class=" nav-profile d-flex gap-2 align-items-center ps-2 pt-1 pb-1 pe-2 bg-neutral-800  ">
                                    <img class="profile-img border-0 object-fit-cover"
                                        :src="user.profile_image_path || require('@/assets/util/images/avatar.png')"
                                        alt="profile image">
                                    <div class="">
                                        <h6 class="d-none d-sm-block text-neutral-25 mb-0 text-4">{{ user.display_name
                                            }}</h6>
                                        <p class="d-none d-sm-block text-neutral-400 text-start text-4">I Am {{
                user.display_name }}
                                        </p>
                                    </div>
                                    <div
                                        class="nav-profile-dropdown text-neutral-25 bg-neutral-900 pt-2 ps-2 pb-2 pe-2 rounded-circle d-flex align-items-center justify-content-center">
                                        <i :class="[profileClicked ? 'fa-angle-up' : 'fa-angle-down', 'fa-solid']"></i>
                                    </div>
                                </div>
                            </button>

                            <div class="dropdown-menu dropdown-menu-end drop-down-container rounded bg-neutral-800">
                                <div>
                                    <div class="position-relative">
                                        <img class="image-cover rounded object-fit-cover"
                                            :src="user.cover_photo_path || require('@/assets/util/images/cover.jpg')"
                                            alt="">
                                        <img class="dropdown-profile-image position-absolute object-fit-cover"
                                            :src="user.profile_image_path || require('@/assets/util/images/avatar.png')"
                                            alt="">
                                    </div>
                                    <div class="ms-2 mt-5 mb-3 me-3 bg-neutral-900 rounded p-2">
                                        <h6 class="text-neutral-25 ">{{ user.display_name }}</h6>
                                        <p v-if="role != 4" class="text-neutral-400 text-3">{{ user.email }}</p>
                                        <!-- <template v-if="role == 2">
                                            <div class="dorpdown-border mt-3"></div>
                                            <h6 class="text-neutral-25 mt-3 text-3">Subscription Plan</h6>
                                            <div class="d-flex  align-items-center justify-content-between">
                                                <h6 class="text-primary mb-0 text-3">Premium Membership</h6>
                                                <button class="btn btn-primary text-3"><i
                                                        class="fa-solid fa-arrow-up"></i>
                                                    <span class="ps-2">Upgrade</span></button>
                                            </div>
                                        </template> -->
                                        <div v-if="role != 4" class="dorpdown-border mt-3"></div>
                                        <div v-if="role != 4"
                                            class="d-flex  align-items-center justify-content-between mt-3">
                                            <h6 class="text-neutral-25 mb-0 text-3">Switch to {{ role == 2 ?
                'Attendee' : 'Host' }}</h6>
                                            <div class="form-check form-switch cursor-pointer">
                                                <input @change="roleSwitchHandler"
                                                    class="role_switcher form-check-input cursor-pointer"
                                                    type="checkbox" name="status" :checked="role === 3"
                                                    id="flexSwitchCheckDefault" />
                                            </div>
                                        </div>
                                        <div class="dorpdown-border mt-3"></div>
                                        <router-link v-if="role != 4"
                                            :to="{ name: role == 2 ? 'host.profile.edit' : 'attendee.profile.edit' }"
                                            class="d-flex bg-neutral-900 border-0 ps-0 align-items-center gap-3 mt-3  cursor-pointer logout">
                                            <h6 class="text-neutral-25 mb-0 pt-2 ps-2 pb-2"><i
                                                    class="fa-solid fa-gear"></i></h6>
                                            <span class="text-neutral-25 text-3">Settings</span>
                                        </router-link>
                                        <div @click="logout"
                                            class="d-flex bg-neutral-900 border-0 ps-0 align-items-center gap-3   cursor-pointer logout">
                                            <h6 class="text-neutral-25 mb-0 pt-2 ps-3 pb-2"><i
                                                    class="fa-solid fa-right-from-bracket"></i></h6>
                                            <span class="text-neutral-25 text-3">Logout</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </nav>
    <LoginAlert ref="loginAlert" />
</template>

<script>
import axios from 'axios';
import { inject, onMounted, ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router'; // Import useRouter from vue-router
import store from '@/store';
import LoginAlert from '@/components/Shared/LoginAlert.vue';

export default {
    components: {
        LoginAlert
    },
    setup() {
        const loginAlert = ref(null);
        const router = useRouter();
        const globalVariables = inject('globalVariables');
        const LoginUser = inject('LoginUser'); // Inject LoginUser
        const AppStorage = inject('AppStorage'); // Inject LoginUser
        const token = ref('');
        const profileClicked = ref(false);
        const user = computed(() => store.getters['user/getUser']);
        const swal = inject('$swal');

        const role = computed(() => {
            return store.getters['user/getUserRole'];
        });
        watch(
            () => store.getters['user/getUserRole'],
            () => {
                role.value = store.getters['user/getUserRole'];
            }
        );
        const profileClick = () => {
            profileClicked.value = !profileClicked.value;
        }
        const handleClickOutside = (event) => {
            const dropdown = document.getElementById('navbarDropdown');
            if (dropdown && !dropdown.contains(event.target)) {
                profileClicked.value = false;
            }
        };
        const logout = () => {
            if (role.value == 4) {
                swal({
                    title: 'Logout',
                    text: 'Please note that if you choose to log out, all your data will be removed.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#6A04BA',
                    confirmButtonText: 'Logout',
                    showConfirmButton: true
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        logoutFunction();
                    }
                })
            } else {
                logoutFunction();
            }
        }

        const logoutFunction = () => {
            axios.post(
                globalVariables.baseURL + '/api/auth/logout',
                null,
                {
                    headers: {
                        Authorization: `Bearer ${token.value}`
                    }
                }
            )
                .then(() => {
                    store.dispatch('user/setAccessToken', null);
                    LoginUser.logout();
                    router.push({ name: 'login' });
                })
        }

        onMounted(async () => {
            token.value = store.getters['user/getAccessToken'];
            document.addEventListener('click', handleClickOutside);
        });

        const roleSwitchHandler = () => {
            store.dispatch('user/setUserRole', role.value == 2 ? 3 : 2);
            if (role.value == 3) {
                window.location = '/attendee/dashboard';
            } else {
                window.location = '/host/dashboard';
            }
        }

        // Return variables and methods from setup()
        return {
            globalVariables,
            LoginUser,
            AppStorage,
            token,
            logout,
            profileClicked,
            profileClick,
            roleSwitchHandler,
            role,
            user,
            loginAlert
        };
    }
}
</script>
<style>
.logout:hover {
    background-color: #242425 !important;
}

.notification {
    padding: 6px 8px 6px 8px;
    aspect-ratio: 1/1;
    border: 1px solid #484848;
}

.nav-profile {
    border-radius: 100px !important;
    border: 1px solid #484848;
}

.dorpdown-border {
    width: 100%;
    background-color: #484848;
    height: 1px;

}

.image-cover {
    width: 100%;
    height: 120px;
    margin-top: -8px;
    object-fit: cover;
}

.drop-down-container {
    width: 350px;
}

.role_switcher:checked {
    background-color: #242425;
}

.role_switcher {
    height: 25px !important;
    width: 52px !important;
}

.form-switch .role_switcher {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236A04BA'/%3e%3c/svg%3e") !important;
    background-color: #242425 !important;
}

.form-switch .role_switcher:focus {
    background-color: #242425 !important;
}

.dropdown-profile-image {
    width: 74px;
    height: 74px;
    border-radius: 200px;
    object-fit: cover;
    left: 10%;
    bottom: 0;
    transform: translate(0, 50%);
}

@media screen and (max-width: 500px) {
    .drop-down-container {
        width: 280px;
    }

    .sb-topnav .navbar-brand img {
        max-width: 164px !important;
    }
}
</style>
