// store.js
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate'; // Import vuex-persist

import eventModule from './modules/hostEvent';
import userModule from './modules/user';
import attendeeEventModule from './modules/attendeeEvent';
import hostProfileModule from './modules/hostProfile';
import dashboardModule from './modules/dashboard';
import stripeModule from './modules/stripe';

export default createStore({
    plugins: [createPersistedState({ key: 'access_token' })],
    modules: {
        user: userModule,
        event: eventModule,
        attendeeEvent: attendeeEventModule,
        hostProfile: hostProfileModule,
        dashboard: dashboardModule,
        stripe: stripeModule,
    }
});
