<template>
    <div class="modal fade" id="eventEditModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog text-neutral-25 ">
            <div class="modal-content bg-neutral-800">
                <div class="modal-header justify-content-between">
                    <h1 class="modal-title text-20 font-weight-600 fs-5" id="exampleModalLabel">UPDATE EVENT</h1>
                    <button type="button " class="bg-neutral-800 border-0" data-bs-dismiss="modal" aria-label="Close">
                        <i class="fa-solid fa-times text-neutral-25"></i>
                    </button>
                </div>
                <div class="modal-body text-neutral-25 ">
                    <form id="eventEditForm" @submit.prevent="updateEvent">
                        <input type="hidden" v-model="newEvent.id">

                        <br>
                        <div class="position-relative">
                            <img v-if="!isCropping" class="coverimage rounded"
                                :src="imagepreview ? imagepreview : event.banner_path || require('@/assets/util/images/cover.jpg')"
                                alt="Selected" />
                            <label for="eventEditBanner" class="file-upload rounded position-absolute">
                                <span for="eventEditBanner upload_icon"><i class="fas fa-pen"></i></span>
                            </label>
                            <cropper class="cover-cropper" v-if="isCropping" :src="imagepreview" :stencil-props="stencilProps" @change="change" ref="cropperRef" />
                            <div class="position-absolute top-0 end-0">
                                <button @click="handleIsCropping" type="button" class="btn bg-transparant">
                                    <i v-if="!isCropping" class="fa-solid fa-crop-simple"></i>
                                    <i v-if="isCropping" class="fa-solid fa-circle-check"></i>
                                </button>
                            </div>
                            <input type="file" class="file d-none" id="eventEditBanner" @change="onFileChange">
                        </div>
                        <div>
                            <label for="eventName" class="mt-3">Event Name:</label> <br>
                        <input class="bg-neutral-700 text-neutral-25 create-event-input rounded mt-1" type="text"
                            id="eventName" placeholder="Event Name" v-model="newEvent.name" required>
                        </div>

                        <div class="d-flex mt-3 gap-3">
                            <div class="event-start">
                                <label for="eventStartTime">Event Start Time:</label>
                                <input class="bg-neutral-700 text-neutral-25 create-event-input rounded mt-1"
                                    type="datetime-local" id="eventStartTime" v-model="newEvent.start_time" required
                                    placeholder="Select a Date & Time" onfocus="this.showPicker()">
                            </div>

                            <div class="event-start">
                                <label for="eventEndTime">Event End Time:</label>
                                <input class="bg-neutral-700  text-neutral-25 create-event-input rounded mt-1"
                                    type="datetime-local" id="eventEndTime" v-model="newEvent.end_time" required onfocus="this.showPicker()"><br>
                            </div>
                        </div>

                        <div>
                            <label class="mt-3" for="eventAddress">Address:</label>
                        <input class="bg-neutral-700 text-neutral-25 create-event-input rounded mt-1" type="text"
                            id="eventAddress" v-model="newEvent.address" placeholder="Address">
                        </div>
                        <div class="d-flex mt-3 gap-3">
                            <div class="w-100">
                                <label for="eventZip">Zip:</label> <br>
                                <input class="bg-neutral-700 text-neutral-25 create-event-input rounded mt-1"
                                    type="text" id="eventZip" v-model="newEvent.zip" placeholder="Zip"><br>
                            </div>
                            <div class="w-100">
                                <label for="eventCity">City:</label> <br>
                                <input class="bg-neutral-700 text-neutral-25 create-event-input rounded mt-1"
                                    type="text" id="eventCity" placeholder="City" v-model="newEvent.city"><br>
                            </div>
                        </div>

                        <div>
                            <label class="mt-3" for="eventState">State:</label> <br>
                        <input class="bg-neutral-700 text-neutral-25 create-event-input rounded mt-1"
                            placeholder="State" type="text" id="eventState" v-model="newEvent.state">
                        </div>

                        <div>
                            <label class="mt-3 mb-3" for="eventCountry">Country:</label> <br>
                            <VueSelect2 v-model="newEvent.country" :options="countries" :placeholder="'Select Country'" :settings="selectSettings" />
                        </div>

                            <div class="server_side_errors"></div>

                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Update</button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import { closeModal, formValidation, serverSideErrorsHtml } from '@/assets/js/utils';
import { useToast } from 'vue-toastification';
import { countries } from '@/assets/js/country.js';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
    components: {
        Cropper,
    },
    props: {
        event: Object,
        message: String,
        updateEventValue: Function
    },
    setup(props) {
        const toast = useToast();
        const store = useStore();
        const setEvent = ref(props.event);
        const imagepreview = ref(null);
        const isCropping = ref(false);
        const cropperRef = ref(null);
        const newEvent = ref({
            id: setEvent.value.id,
            name: setEvent.value.name,
            start_time: setEvent.value.start_time,
            end_time: setEvent.value.end_time,
            address: setEvent.value.address,
            zip: setEvent.value.zip,
            city: setEvent.value.city,
            state: setEvent.value.state,
            country: setEvent.value.country,
            banner: null,
        });
        const selectSettings = {
            dropdownParent: '#eventEditForm',
        };

        const updateEvent = async () => {
            const isValid = formValidation(document.getElementById('eventEditForm'));
            if (isValid) {
                const eventData = {
                    id: newEvent.value.id,
                    name: newEvent.value.name,
                    start_time: newEvent.value.start_time,
                    end_time: newEvent.value.end_time,
                    address: newEvent.value.address,
                    zip: newEvent.value.zip,
                    city: newEvent.value.city,
                    state: newEvent.value.state,
                    country: newEvent.value.country,
                    banner: imagepreview.value,
                    hostId: localStorage.getItem('userID'),
                };
                const response = await store.dispatch('event/updateEvent', eventData);

                if (response.data.status) {
                    props.updateEventValue(store.getters['event/eventDetailsData']);
                    closeModal('eventEditModal');
                    toast.success(response.data.msg);
                } else {
                    if (response.status == 422) {
                        serverSideErrorsHtml(response, 'eventEditForm')
                    } else {
                        toast.error(response.data.msg);
                    }
                }
            }
        };

        const onFileChange = (event) => {
            newEvent.value.banner = event.target.files[0];
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                imagepreview.value = reader.result;
            };
            reader.readAsDataURL(file);
        };

        const handleIsCropping = () => {
            isCropping.value = !isCropping.value;
            if (!isCropping.value) {
                const { coordinates, canvas, } = cropperRef.value.getResult();
                // coordinates.value = coordinates;
                console.log(coordinates)
                imagepreview.value = canvas.toDataURL();
                newEvent.value.banner = canvas.toDataURL();
            }
        }

        watch(() => props.event, (newValue) => {
            setEvent.value = { ...newValue };
            newEvent.value = { ...setEvent.value };
            imagepreview.value = 'data:image/png;base64,' + newEvent.value.banner_base_path;
        });

        return {
            newEvent,
            onFileChange,
            updateEvent,
            imagepreview,
            countries,
            selectSettings,
            cropperRef,
            isCropping,
            handleIsCropping
        };
    }
};
</script>
<style scoped>
.event-start {
    width: 48% !important;
}

.create-event-input {
    width: 100% !important;
    border: 2px solid #A4A4A5;
    height: 44px;
    padding-left: 10px;

}

.create-event-input::placeholder {
    /* padding-left: 10px; */
    color: #FCFCFC;
}

.modal-footer {
    border-top: none !important;
}

label .file[type='file'] {
    display: none;
}

.upload_icon {
    cursor: pointer;
    background-color: #171718;
    padding: 10px 15px;
    border-radius: 4px;

}

.file-upload {
    /* width: 100%;
    height: 250px;
    background-color: #333334;
    display: flex;
    align-items: center;
    justify-content: center; */
    width: 100%;
    display: flex;
    justify-content: center;
    top: 45%;
}

.coverimage {
    width: 100%
}
.cover-cropper {
    width: 100%;
    height: 180px;
}
</style>
