<template>
    <h3 class="auth_page_titlte text-neutral-100">Registration</h3>
    <p class="text-neutral-200 mb-3">Please provide the necessary information to register</p>

    <form class="w-100" @submit.prevent="signup">
        <!-- <div v-if="errorMessages.length > 0"
            :class="['alert', 'alert-danger', 'd-flex', 'align-items-center', 'alert-dismissible', { 'fade': errorMessages.length > 0, 'show': errorMessages.length > 0 }]"
            role="alert">
            <i class="fa-solid fa-triangle-exclamation me-2"></i>
            <div>
                <span v-html="errorMessages.join('</br> ')"></span>
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div> -->

        <div v-for="(error, index) in errorMessages" :key="index" class="alert alert-danger alert-dismissible fade show" role="alert">
            {{ error.message }}
            <button type="button" @click="removeAlert(error.id)" class="btn-close"></button>
        </div>

        <div class="form-floating mb-3">
            <input type="text" class="form-control" id="name" placeholder="Name" v-model="form.name" required>
            <label for="name">Name <span class="text-danger">*</span></label>
        </div>
        <div class="form-floating mb-3">
            <input type="email" class="form-control" id="email" placeholder="Email" v-model="form.email" required>
            <label for="email">Email <span class="text-danger">*</span></label>
        </div>
        <div class="form-floating mb-3" @click="handleClickOnPhone">
            <vue-tel-input @input="handleNumberIn" @on-input="handleNumberInput" v-model="form.phone" @country-changed="handleCountryChange" :validCharactersOnly="true"></vue-tel-input>
            <p v-if="!isValidNumber && numberChanged" class="text-danger">The phone number is not valid.</p>
        </div>
        <div class="form-floating mb-3 position-relative">
            <input :type="showPassword ? 'text' : 'password'" class="form-control" id="password" placeholder="Password"
                v-model="form.password" required>
            <label for="password">Password <span class="text-danger">*</span></label>
            <div class="eye-icon-container position-absolute top-0 bottom-0 end-0 d-flex align-items-center">
                <button type="button" class="btn eye-icon-btn" @click="togglePasswordVisibility"><i
                        :class="[showPassword ? 'fa-eye' : 'fa-eye-slash', 'fa-regular']"></i></button>
            </div>
        </div>
        <div class="form-floating mb-3 position-relative">
            <input :type="showPassword ? 'text' : 'password'" class="form-control" id="confirm_password"
                placeholder="Confirm Password" v-model="form.password_confirmation" required>
            <label for="confirm_password">Confirm Password <span class="text-danger">*</span></label>
            <div class="eye-icon-container position-absolute top-0 bottom-0 end-0 d-flex align-items-center">
                <button type="button" class="btn eye-icon-btn" @click="togglePasswordVisibility"><i
                        :class="[showPassword ? 'fa-eye' : 'fa-eye-slash', 'fa-regular']"></i></button>
            </div>
        </div>
        <button type="submit" :disabled="btnLoading" :class="[btnLoading ? 'btn_loading' : '', 'btn btn-primary w-100']">Register</button>
    </form>
    <p class="text_middle_line mt-3 mb-3">Already have an account?</p>
    <router-link class="btn w-100 text-neutral-25 border-primary" :to="{ name: 'login' }">Login</router-link>
</template>

<script>
import axios from 'axios'; // Import Axios here
import { inject, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useRouter } from 'vue-router';
import store from '@/store';

export default {
    setup() {
        const router = useRouter();
        const globalVariables = inject('globalVariables');
        const toast = useToast();
        const showPassword = ref(false);
        const isValidNumber = ref(false);
        const numberChanged = ref(false);
        const btnLoading = ref(false);
        const form = ref({
            name: null,
            email: null,
            phone: null,
            password: null,
            password_confirmation: null
        });
        const errorMessages = ref([]);

        const signup = async () => {
            btnLoading.value = true;
            try {
                if (isValidNumber.value) {
                    const response = await axios.post(globalVariables.baseURL + '/api/auth/signup', form.value);
                    if (response.data.status) {
                        store.dispatch('user/setRegisteringEmail', form.value.email);
                        router.push({ name: 'auth.email.verification' });
                        btnLoading.value = false;
                    } else {
                        toast.error('Something went wrong!');
                        btnLoading.value = false;
                    }
                } else {
                    numberChanged.value = true;
                    btnLoading.value = false;
                }
            } catch (error) {
                btnLoading.value = false;
                if (error.response && error.response.status === 422) {
                    const errors = error.response.data.data;
                    errorMessages.value = [];
                    for (const field in errors) {
                        errors[field].forEach(message => {
                            let lastElement = errorMessages.value.pop();
                            if (!lastElement) {
                                lastElement = {id: 0};
                            }
                            const messageObj = {
                                id: lastElement.id + 1,
                                message: message
                            };
                            errorMessages.value.push(messageObj);
                        });
                    }
                } else {
                    errorMessages.value = [];
                    errorMessages.value = [{id: 1, message: error.response.data.msg}];
                }
            }
        };

        const removeAlert = id => {
            errorMessages.value = errorMessages.value.filter(error => error.id !== id);
        }

        const handleCountryChange = (countryObject) => {
            form.value.phone = '+' + countryObject.dialCode; // Access form's phone using .value
        };

        const togglePasswordVisibility = () => {
            showPassword.value = !showPassword.value;
        };

        const handleNumberInput = (number, phoneObject) => {
            isValidNumber.value = phoneObject.valid ? true : false;
        }
        const handleClickOnPhone = () => {
            numberChanged.value = true;
        }

        return {
            form,
            signup,
            handleCountryChange,
            togglePasswordVisibility,
            showPassword,
            errorMessages,
            removeAlert,
            isValidNumber,
            handleNumberInput,
            btnLoading,
            numberChanged,
            handleClickOnPhone
        };
    },
};
</script>



<style>
.vti__dropdown {
    background-color: #333334;
}

.vti__input {
    background-color: #333334;
    color: #FCFCFC;
}

.vti__input::placeholder {
    color: #E4E4E5;
}

.vti__dropdown-list {
    background-color: #333334 !important;
    color: #FCFCFC !important;
    z-index: 99 !important;
}

.vti__dropdown-item.highlighted {
    background-color: #5e5e5e !important;
}
</style>
