<template>
    <div class="card bg-neutral-800">
        <img class="card-img-top" :src="event?.banner_path || require('@/assets/util/images/no_event_banner.jpg')" alt="Card image cap">
        <div class="card-body d-flex gap-2 ">
            <button class="card-date  text-primary bg-neutral-900 text-24 font-weight-bold rounded lh-1">{{
            event.start_date_month }}</button>
            <div class="d-flex flex-column align-items-start">
                <h6 class="text-neutral-25 text-4">{{ event.name }}</h6>
                <p class="d-flex align-items-baseline gap-2"><i class="fa-solid fa-location-dot text-primary text-3"></i>
                    <span class="text-neutral-25 text-3 text-start text-break">{{ event.address || 'N/A' }}</span>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default ({
    props: {
        event: Object
    }
})
</script>
