<template>
  <template v-if="userLoading">
    <LoadingComponent />
  </template>
  <template v-else>
    <div class="position-relative">
      <img
        class="cover-image position-relative rounded"
        :src="user?.cover_photo_path || require('@/assets/util/images/cover.jpg')"
        alt=""
      />
      <img
        class="profile-image rounded-circle position-absolute bottom-0 start-50 object-fit-cover"
        :src="user?.profile_image_path || require('@/assets/util/images/avatar.png')"
        alt=""
      />
    </div>
    <div class="profile-short-info-card mt-3">
      <div class="rating-container">
        <div class="rating-cards gap-3">
          <div class="d-flex">
            <div class="bg-neutral-800 d-flex align-items-center tips gap-3 rounded-pill">
              <span class="tips-icon bg-neutral-900 rounded-circle"
                ><i class="fa-solid fa-star star"></i
              ></span>
              <span>
                <p class="text-neutral-200 text-3">Rating</p>
                <h6 class="text-neutral-25 text-4">
                  {{ user?.rating }}
                  <span class="number_of_rating">({{ user?.total_review }})</span>
                </h6>
              </span>
            </div>

            <button
              v-if="viewAsAttendee"
              class="rounded share-qr btn-primary font-weight-bold ms-2 text-neutral-25 font-weight-600 h-fit-content"
            >
              <i class="fa-solid fa-star"></i>
              <span class="ms-2 d-none d-lg-inline-block">RATE</span>
            </button>
          </div>
        </div>
      </div>
      <div class="profile__short__info--right">
        <div class="d-flex">
          <div>
            <button
              v-if="viewAsAttendee"
              class="me-2 rounded share-qr btn-primary font-weight-bold ms-2 text-neutral-25 font-weight-600"
            >
              <i class="fa-solid fa-dollar-sign"></i>
              <span class="d-none d-lg-inline-block">SEND TIP</span>
            </button>
            <template v-if="viewAsAttendee">
              <button
                class="rounded share-qr bg-neutral-800 text-neutral-25 font-weight-bold text-4"
              >
                <i class="fa-solid fa-qrcode"></i>
                <span class="text-4 ms-2 d-none d-lg-inline-block">SHARE</span>
              </button>
            </template>
            <template v-else>
              <button
                @click="openModal('qrcodeModal')"
                class="rounded share-qr text-neutral-800 font-weight-bold text-4"
              >
                <i class="fa-solid fa-qrcode"></i>
                <span class="text-4 ms-2 d-none d-lg-inline-block">SHARE</span>
              </button>
            </template>
          </div>
          <div>
            <span class="dropdown">
              <button
                data-bs-toggle="dropdown"
                class="rounded share-qr bg-neutral-800 font-weight-bold ms-2 text-neutral-25"
              >
                <i class="fa-solid fa-ellipsis-vertical"></i>
              </button>
              <ul class="dropdown-menu dropdown-ul bg-neutral-800">
                <li class="">
                  <router-link
                    :to="{ name: 'host.profile.edit' }"
                    class="dropdown-item text-neutral-25"
                  >
                    <i class="fa-solid fa-pen"></i> <span class="ps-2">Edit Profile</span>
                  </router-link>
                </li>
                <li>
                  <button
                    @click="handleViewAsAttendee"
                    class="dropdown-item text-neutral-25"
                    href="#"
                  >
                    <i class="fa-solid fa-eye"></i>
                    <span class="ps-2"
                      >View as {{ viewAsAttendee ? "host" : "attendee" }}
                    </span>
                  </button>
                </li>
              </ul>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center user_info mb-4">
      <h3 class="text-neutral-25 text-28 font-weight-600">{{ user?.display_name }}</h3>
      <p class="text-neutral-300 text-center px-1 px-lg-5">{{ user?.bio }}</p>
    </div>

    <!-- Social Links Section -->
    <section class="bg-neutral-800 text-neutral-25 rounded px-3 py-3 row">
      <div class="position-relative d-flex justify-content-center">
        <h3 class="text-neutral-25 text-20">Social Links</h3>
        <button
          type="button"
          @click="openModal('editSocialLinkModal')"
          class="btn bg-neutral-900 text-neutral-25 position-absolute end-0 top-0"
        >
          <i class="fa-solid fa-pencil"></i>
        </button>
      </div>
      <div class="row justify-content-center">
        <div class="d-flex justify-content-center gap-3 col-12 col-md-10 flex-wrap">
            <template v-if="user.social_links?.length">
                <a
        target="_blank"
            class="d-flex align-items-center gap-2 bg-neutral-900 px-3 rounded-pill border py-1 text-neutral-25"
          :href="social_link?.link"
          v-for="social_link in user?.social_links"
          :key="social_link.id"
        >
          <img
            class="profile_page_social_link_logo"
            :src="social_link?.logo"
            :alt="social_link?.name"
          />
          {{ social_link?.name }}
        </a>
        </template>
        <p v-if="!user.social_links?.length">Please add some social links to show here.</p>
      </div>
      </div>
    </section>

    <div class="mt-5">
      <div class="d-flex justify-content-between">
        <h3 class="text-neutral-25 text-20"> Events</h3>
        <!-- <button class=" see-all-btn btn-primary ps-4 pe-4 rounded">SEE ALL <i
                    class="fa-solid fa-arrow-right ms-2"></i></button> -->
      </div>
      <div class="mt-3 cards row upcoming-events">
        <template v-if="eventLoading">
          <LoadingComponent />
        </template>
        <template v-else>
          <template v-if="upcomingEvents.length">
            <router-link
              :to="{ name: 'host.event.details', params: { eventId: event.id } }"
              v-for="(event, index) in upcomingEvents"
              :key="index"
              class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 pb-3"
            >
              <EventCard :event="event" />
            </router-link>
          </template>
          <template v-if="!upcomingEvents.length">
            <NoData />
          </template>
        </template>
      </div>
    </div>
    <QrcodeModal
      :src="user?.qrcode_path"
      :code="user?.code"
      :basePath="user?.qrcode_base_path"
      :url="user?.url"
      :type="'profile'"
    />

    <EditSocialLink :social_links="user?.social_links" :fetchUserDetails="fetchUserDetails" />
  </template>

  <div v-if="viewAsAttendee" class="viewing">You are viewing as attendee...</div>
</template>
<script>
import { onMounted, ref } from "vue";
import { useToast } from "vue-toastification";
import { useStore } from "vuex";
import QrcodeModal from "@/components/Host/Event/QrcodeModal.vue";
import { openModal } from "@/assets/js/utils";
import EventCard from "@/components/Shared/EventCard.vue";
import LoadingComponent from "@/components/Shared/LoadingComponent.vue";
import NoData from "@/components/Shared/NoData.vue";
import EditSocialLink from "@/components/Host/Profile/EditSocialLink.vue";

export default {
  components: {
    QrcodeModal,
    EventCard,
    LoadingComponent,
    NoData,
    EditSocialLink,
  },
  setup() {
    const store = useStore();
    const user = ref({});
    const upcomingEvents = ref([]);
    const userLoading = ref(true);
    const eventLoading = ref(true);
    const viewAsAttendee = ref(false);

    const fetchUserDetails = async () => {
      try {
        userLoading.value = true;
        await store.dispatch("hostProfile/fetchUserDetails");
        user.value = store.getters["hostProfile/getProfileInfoData"];
        userLoading.value = false;
      } catch (error) {
        console.error(error);
      }
    };
    const fetchHostUpcomingEvents = async () => {
      try {
        eventLoading.value = true;
        await store.dispatch(
          "event/fetchHostUpcomingEvents",
          store.getters["user/getUserId"]
        );
        upcomingEvents.value = store.getters["event/getHostUpcomingEvents"];
        eventLoading.value = false;
      } catch (error) {
        console.error(error);
      }
    };

    let userProfileCode = null;
    let userQrCode = null;
    const toast = useToast();

    const profileCode = () => {
      navigator.clipboard.writeText(userProfileCode).then(() => {
        toast.success("Copy success");
      });
    };
    const copyQrCode = () => {
      navigator.clipboard.writeText(userQrCode).then(() => {
        toast.success("Copy success");
      });
    };
    const downloadQrCode = () => {
      const base64SVG = user.value.qrcode_base_path;

      // Step 2: Create a new Image element
      var img = new Image();
      img.onload = function () {
        // Step 3: Draw the Image on a Canvas
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        // Step 4: Convert Canvas to PNG
        var dataURL = canvas.toDataURL("image/png");

        // Step 5: Download the PNG
        var link = document.createElement("a");
        link.download = "image.png";
        link.href = dataURL;
        link.click();
      };
      img.src = "data:image/svg+xml;base64," + base64SVG;
    };

    onMounted(async () => {
      await fetchUserDetails();
      await fetchHostUpcomingEvents();

      const profileCodeElement = document.querySelector(".profile-code");
      if (profileCodeElement) {
        userProfileCode = profileCodeElement.innerText;
      }
      const copyQrCodeElement = "my-profile-url";
      if (copyQrCodeElement) {
        userQrCode = copyQrCodeElement;
      }
    });

    const handleViewAsAttendee = () => {
      viewAsAttendee.value = !viewAsAttendee.value;
    };

    return {
      profileCode,
      copyQrCode,
      downloadQrCode,
      user,
      upcomingEvents,
      handleViewAsAttendee,
      openModal,
      userLoading,
      eventLoading,
      viewAsAttendee,
      fetchUserDetails,
    };
  },
};
</script>
<style scoped>
.profile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-short-info-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cover-image {
  height: 280px;
  width: 100%;
  object-fit: cover;
}

.profile-image {
  width: 180px;
  height: 180px;
  border: 5px solid #242425;
  transform: translate(-50%, 50%);
}

.tips {
  padding: 5px 15px 5px 10px;
}

.tips-icon {
  padding: 7px 12px 7px 12px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-self: center;
}

.star {
  color: rgba(243, 137, 0, 0.933);
}

.tips-icon i {
  font-size: 18px;
}

.share-qr {
  padding: 10px 15px 10px 15px;
  border: none;
}

.card {
  border: 1px solid #484848 !important;
}

.card-date {
  font-weight: bold;
  border: none;
}

.qr-code {
  width: 244px;
  height: 244px;
}

.border-separator {
  width: 1px;
  height: 50%;
  background-color: #747475;
}

.modal-header {
  border-bottom: none !important;
}

.dropdown-ul {
  right: 42px !important;
}

.dropdown-menu {
  --bs-dropdown-link-hover-bg: none !important;
  --bs-dropdown-link-active-bg: none !important;
}

@media (max-width: 992px) {
  .profile-image {
    width: 120px;
    height: 120px;
  }

  .modal-content-wrapper {
    flex-direction: column !important;
    align-items: center;
    gap: 50px;
  }

  .border-content {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .profile-image {
    width: 120px;
    height: 120px;
  }

  .cover-image {
    height: 180px;
  }
}

.viewing {
  position: fixed;
  top: 16px;
  left: 50%;
  z-index: 99999;
  color: #747475;
  transform: translateX(-50%);
}
</style>
